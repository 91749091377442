import React, { useEffect } from "react"
import { withPrefix } from "gatsby-link"

const RedirectIndex = () => {
  useEffect(() => {
    const availableLanguages = ["en", "es", "pt"]
    let langKey = ""
    // search for browser languages
    if (typeof window !== "undefined") {
      for (const element of window.navigator.languages) {
        if (availableLanguages.includes(element.substring(0, 2))) {
          langKey = element.substring(0, 2)
          break
        }
      }

      // defaults to English
      if (langKey === "") langKey = "pt-br"
      if (langKey === "pt") langKey = "pt-br"
      const homeUrl = withPrefix(`/${langKey}/404`)
      window.location.replace(homeUrl)
    }
  }, [])

  return (
    <></>
  )
}

export default RedirectIndex
